import React, { useContext } from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import { Layout } from '../components/Common/';
import AboutMe from '../components/About/AboutMe/AboutMe';
import { SetLocationContext } from '../providers/LocationProvider';

const About = (props) => {
  const { formatMessage } = useIntl();
  const setLocation  = useContext(SetLocationContext);
  setLocation(formatMessage({ id: 'navLink2' }));
  return ( 
    <Layout>
      <AboutMe />
    </Layout>   
  );
}
 
export default About;