import { graphql, useStaticQuery } from 'gatsby';

const usePersonalImage = () => {
  const data = useStaticQuery(graphql`
    query {
      file (name: { eq: "me" }) {
        name
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)

  return {
    ...data.file.childImageSharp,
    alt: data.name,
  }
}

export default usePersonalImage;