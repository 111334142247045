import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import Image from 'gatsby-image';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import { 
  FaEnvelope, 
  FaPhone, 
  FaMapMarkerAlt, 
  FaTwitter, 
  FaInstagram,
  FaGithub,
  FaLinkedin,
  FaYoutube
} 
from 'react-icons/fa';
import usePersonalImage from '../../../graphql/queries/usePeronalImage';
import './AboutMe.scss';

const AboutMe = (props) => {
  const { locale } = useIntl();
  const image = usePersonalImage();
  return ( 
    <section className="w-11/12 lg:w-4/5 mx-auto pt-32 md:pt-40 ">
      <h1 className="text-6xl font-bold text-center md:text-left">
       {<FormattedMessage id="meInfo" />}
      </h1>
      <article className="flex flex-col mt-4 md:mt-0 lg:flex-row md:px-4">
        <div className="w-full lg:w-2/4 md:mr-4">
          <p className="text-3xl">
            {<FormattedMessage id="meDescription" />}
          </p>
          <p className="text-3xl my-4">
            {<FormattedMessage id="actualWork" />}
          </p>
          <p className="text-3xl my-4">
            {<FormattedMessage id="businessMessage" />}
          </p>
          <ul className="text-3xl pl-8">
            <li className="flex items-center mb-4">
              <span className="mr-4"><FaEnvelope /></span>
              <span>olivielultimate@gmail.com</span>
            </li>
            <li className="flex items-center mb-4">
              <span className="mr-4"><FaPhone /></span>
              <span>849-281-8023</span>
            </li>
            <li className="flex items-center mb-4">
              <span className="mr-4"><FaMapMarkerAlt /></span>
              <span>Santo Domingo, {<FormattedMessage id="country" />}</span>
            </li>
          </ul>
        </div>
        <div className="lg:w-2/4 mt-12 lg:mt-0">
          { image.fluid ? 
            <Image
              fluid={image.fluid}
              alt={image.alt}
            />
              :
            <img src="https://i.redd.it/cbhb8vvek8x11.jpg" className="personal-image" alt=""/>
          }
        </div>
      </article>
      <article className="mt-8">
        <h1 className="text-4xl font-bold mb-8">
        {<FormattedMessage id="websites" />}
        </h1>
        <div className="flex text-4xl">
          <ul className="mr-24 md:mr-40">
            <li> 
              <OutboundLink className="flex items-center mb-4" href={`${locale === 'en' ? 'https://twitter.com/holiviel_en' : 'https://twitter.com/holiviel_es' }`} target="_blank" rel="noopener noreferrer">
                <span className="mr-4"><FaTwitter /></span>
                <span>Twitter</span>
              </OutboundLink>
            </li>
            <li> 
              <OutboundLink className="flex items-center mb-4" href={`${locale === 'en' ? 'https://www.instagram.com/holiviel_valdez_en' : 'https://www.instagram.com/holiviel_valdez' }`} target="_blank" rel="noopener noreferrer">
                <span className="mr-4"><FaInstagram /></span>
                <span>Instagram</span>
              </OutboundLink>
            </li>
            <li>
              <OutboundLink className="flex items-center mb-4" href="https://www.linkedin.com/in/hordanel-valdez-488ba610a/" target="_blank" rel="noopener noreferrer">
                <span className="mr-4"><FaLinkedin /></span>
                <span>Linkedin</span>
              </OutboundLink>
            </li>
          </ul>
          <ul>
            <li>
              <OutboundLink className="flex items-center mb-4" href="https://github.com/oliviel" target="_blank" rel="noopener noreferrer">
                <span className="mr-4"><FaGithub /></span>
                <span>Github</span>
              </OutboundLink>
            </li>
            <li>
            <OutboundLink className="flex items-center mb-4" href="https://www.youtube.com/channel/UC2yllAaom7paEMqNJQGF8jw" target="_blank" rel="noopener noreferrer">
              <span className="mr-4"><FaYoutube /></span>
              <span>Youtube</span>
            </OutboundLink>
            </li>
          </ul>
        </div>
      </article>
    </section>
  );
}
 
export default AboutMe;